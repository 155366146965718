(function (windowObj) {
  "use strict";
  windowObj = windowObj || window;
  var readyList = [];
  var readyFired = false;
  var readyEventHandlersInstalled = false;

  function ready () {
    if (!readyFired) {
      readyFired = true;
      for (var i = 0; i < readyList.length; i++) {
        readyList[i].fn.call(window, readyList[i].ctx);
      }
      readyList = [];
    }
  }

  function readyStateChange () {
    if (document.readyState === "complete") {
      ready();
    }
  }

  windowObj.flowFeedsDocReady = function (callback, context) {
    if (readyFired) {
      setTimeout(function () {
        callback(context);
      }, 1);
      return;
    } else {
      readyList.push({fn: callback, ctx: context});
    }
    if (document.readyState === "complete" || (!document.attachEvent && document.readyState === "interactive")) {
      setTimeout(ready, 1);
    } else if (!readyEventHandlersInstalled) {
      if (document.addEventListener) {
        document.addEventListener("DOMContentLoaded", ready, false);
        window.addEventListener("load", ready, false);
      } else {
        document.attachEvent("onreadystatechange", readyStateChange);
        window.attachEvent("onload", ready);
      }
      readyEventHandlersInstalled = true;
    }
  };


  flowFeedsDocReady(function () {
    for (var i = 0; i < window.flowfeeds.length; i++) {
      var defaultOptions = {
        dateFormat: 'd mmmm yyyy'
      };

      var feedConfig = extendObject(defaultOptions, window.flowfeeds[i]);

      buildWidget(feedConfig);
    }
  });

  function buildWidget (feedConfig) {
    var uriParts = [window.location.hostname === "localhost" || window.location.hostname === "flowfeeds.site" ? 'http://flowfeeds.site/api/feeds' : 'https://feeds.flowsa.net/api/feeds'];
    uriParts.push(feedConfig.agency);
    feedConfig.project && uriParts.push(feedConfig.project);
    feedConfig.feed && uriParts.push(feedConfig.feed);
    var query = {};

    if (feedConfig.limit) {
      query.limit = feedConfig.limit;
    }
    if (feedConfig.exclude) {
      query.exclude = feedConfig.exclude.join(',');
    }

    var uri = buildUrl(uriParts.join('/'), query);

    var viewData = {};

    viewData.loading = true;

    feedConfig.element.innerHTML = flowfeedsTemplates['my-template'].render(viewData);

    fetch(uri)
      .then(function (response) {
        return response.json();
      }).then(function (json) {

      viewData.data = json.data;
      viewData.media = json.media;
      viewData.first_media = json.first_media;

      // Process data
      for (var i = 0, len = viewData.data.length; i < len; i++) {
        var item = viewData.data[i];
        //console.log('item', i, item);
        item.posted_at = dateToDate(item.posted_at).format(feedConfig.dateFormat);

        if (item.data.responses) {
          for (var a = 0; a < item.data.responses.length; a++) {
            item.data.responses[a].count_formatted = numerKFormat(item.data.responses[a].count);
          }
        }

        if (item.data.text && item.data.text.length > 500) {
          item.data.truncateText = true;
        }
        if (item.data.text) {
          item.data.textLength = item.data.text.length;
        }
      }

      feedConfig.element.innerHTML = flowfeedsTemplates['my-template'].render(viewData);

      var msnry = new Masonry(feedConfig.element, {
        itemSelector: '.flowfeeds-item',
        gutter: 20,
        transitionDuration: 0,
        initLayout: false
        //columnWidth: 200
      });

      var loader = imagesLoaded(feedConfig.element, function () {
        msnry.layout();
        var container = feedConfig.element.children[0];

        container.className = container.className.replace(/\bloading\b/,'');

        if (feedConfig.onComplete) {
          feedConfig.onComplete(feedConfig.element, msnry);
        }
      });

    }).catch(function (ex) {
      console.log('parsing failed', ex)
    });
  }

  function dateToDate(str) {
    // Split timestamp into [ Y, M, D, h, m, s ]
    var t = str.split(/[- :]/);

    // Apply each element to the Date function
    return new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
  }


  function extendObject (defaults, target) {
    for (var key in defaults) {
      if (defaults.hasOwnProperty(key)) {
        target[key] = defaults[key];
      }
    }

    // Return the modified object
    return target;
  }

  function buildUrl (url, parameters){
    var qs = "";
    for(var key in parameters) {
      var value = parameters[key];
      qs += encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
    }
    if (qs.length > 0){
      qs = qs.substring(0, qs.length-1); //chop off last "&"
      url = url + "?" + qs;
    }
    return url;
  }

  function numerKFormat(num) {
    return num > 999 ? (num/1000).toFixed(1) + 'k' : num
  }

})(window);

////

window.flowfeeds = window.flowfeeds || [];
